import generator from "generate-password";

export default function () {
  return generator.generate({
    length: 12,
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: true,
    strict: true,
  });
}
