import { helpers, minLength, required } from "@vuelidate/validators";

const isGoodPassword = (value: unknown) =>
  (value as string).match(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).+$/
  )?.shift
    ? true
    : false;

export default function () {
  return {
    required: helpers.withMessage("Du skal indtaste et kodeord", required),
    minLength: helpers.withMessage(
      "Kodeordet skal være mindst 12 karakterer langt",
      minLength(12)
    ),
    isGoodPassword: helpers.withMessage(
      "Skal indeholde et lille og STORT bogstav, 1 tal, og mindst en special karakter!",
      isGoodPassword
    ),
  };
}
